table.student-table {
    width: 100%;
    border-collapse: collapse;
}

table.student-table tr th {
    text-align: center;
    padding: 10px;
    background-color: #f2f2f2;
}

table.student-table tr td {
    text-align: center;
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

.histogram-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* Adjust spacing between charts */
    align-items: flex-start;
    gap: 20px; /* Space between rows */
}

.histogram-container {
    flex-basis: calc(33.33% - 20px); /* 3 containers per row, minus gap */
    box-sizing: border-box;
    padding: 10px; /* Optional padding around each histogram */
}

.statistics {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* Adjust spacing between charts */
    align-items: flex-start;
    gap: 1px; /* Space between rows */

}

.statistics p {
    flex-basis: calc(33.33% - 1px); /* 3 containers per row, minus gap */
    box-sizing: border-box;
    padding: 2px; /* Optional padding around each histogram */
}