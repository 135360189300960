.idioms_panel {
    position: relative;
    padding: 10px;
}

.idioms_panel .search_placeholder {
    background-color: white;
    height: 40px;
    padding: 10px;
}

.idioms_panel .search_placeholder .input {
    width: 100%;
}

.idioms_panel .search_placeholder .input input {
    width: 100%;
}

.idioms_panel .list_placeholder .idioms_list .idiom_item {
    padding: 10px;
    border-bottom: 1px solid #F3F3F8;
    background-color: white;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.idioms_panel .list_placeholder .idioms_list .idiom_item:hover {
    opacity: 0.7;
}

.idioms_panel .list_placeholder .idioms_list .idiom_item:hover .name_placeholder .name .view_example {
    display: inline-block;
}

.idioms_panel .list_placeholder .idioms_list .idiom_item .name_placeholder .name {
    font-weight: bold;
    line-height: 28px;
    font-size: 18px;
    color: #858598;
}

.idioms_panel .list_placeholder .idioms_list .idiom_item .name_placeholder .name .view_example {
    opacity: 0.6;
    margin-left: 20px;
    font-style: italic;
    font-weight: normal;
    display: none;
}

.idioms_panel .list_placeholder .idioms_list .idiom_item .description_placeholder .description {
    font-size: 16px;
    line-height: 24px;
}

.idioms_panel .list_placeholder .idioms_list .idiom_item .description_placeholder .description b {
    opacity: 0.6;
}

.words_panel {
    position: relative;
}

.words_panel .search_placeholder {
    height: 40px;
    background-color: white;
    padding: 10px;
    border-bottom: 1px solid #F3F3F8;
}

.words_panel .search_placeholder .input {
    width: 100%;
}

.words_panel .alphabet_placeholder {
    text-align: center;
    height: 40px;
    text-align: center;
    background-color: white;
}

@media (max-width: 720px) {
    .words_panel .alphabet_placeholder {
        height: 80px;
    }
}

.words_panel .alphabet_placeholder .letter_item {
    display: inline-block;
    vertical-align: top;
    margin-right: 4px;
    margin-left: 4px;
    height: 40px;
    line-height: 40px;
    font-size: 24px;
    color: #858597;
    cursor: pointer;
    opacity: 0.8;
}

.words_panel .alphabet_placeholder .letter_item:hover {
    opacity: 1;
}

.words_panel .list_placeholder .letter_section .letter_block {
    background-color: white;
    font-weight: bold;
    color: #858598;
    font-size: 24px;
    padding: 10px;
    line-height: 32px;
    text-align: center;
}

.words_panel .list_placeholder .letter_section .words_list .word_item {
    display: inline-block;
    margin: 10px;
    width: calc(25% - 20px);
    vertical-align: top;
    min-height: 70px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #F3F3F8;
    background-color: white;
    cursor: pointer;

}

@media (max-width: 720px){
    .words_panel .list_placeholder .letter_section .words_list .word_item {
        width: 44%;
    }
}

.words_panel .list_placeholder .letter_section .words_list .word_item:hover {
    opacity: 0.8;
}

.words_panel .list_placeholder .letter_section .words_list .word_item .name {
    font-weight: bold;
    color: #858598;
    font-size: 18px;
    line-height: 50px;
    text-align: center;
}